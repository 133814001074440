<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Hébergement" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <base-subtitle subtitle="Capacité de vos infrastructures d'hébergement" />
          <base-commentaire>Préciser ici vos capacités globalement pour {{ entite.nom }}. Vous pourrez détailler ces capacités par local IT dans la partie suivante.</base-commentaire>

          <form-fields-text 
            v-model="infra_hebergement_nombre_serveurs_physiques"
            field-label="Nombre de serveurs physiques"
            placeholder="Nombre de serveurs physiques"
            type="number"
            number-integer
            @blur="saveFormAnswer"
          />
          <form-fields-text 
            v-model="infra_hebergement_nombre_vm"
            field-label="Nombre de VM"
            placeholder="Nombre de VM"
            type="number"
            number-integer
            @blur="saveFormAnswer"
          />
          <form-fields-text 
            v-model="infra_hebergement_nombre_applications"
            field-label="Nombre d'applications"
            placeholder="Nombre d'applications"
            type="number"
            number-integer
            @blur="saveFormAnswer"
          />
          <form-fields-text 
            v-model="infra_hebergement_nombre_serveurs_dedies_bdd"
            field-label="Nombre de serveurs dédiés à une base de données"
            placeholder="Nombre de serveurs dédiés"
            field-description="Indiquer 0 si aucun serveur n'est dédié à une base de données"
            type="number"
            number-integer
            @blur="saveFormAnswer"
          />
          <!-- <base-subtitle 
            subtitle="Préciser votre hébergement par type de service"
            class="mt-8" 
          />
          <v-row class="d-flex justify-end">
            <v-col cols="6 offset-6">
              <v-row>
                <v-col 
                  v-for="(hebergement,i) in herbergementList" 
                  :key="i"
                  cols="3" 
                  class="d-flex justify-center"
                >
                  <span class="text-body-2">{{ hebergement }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row 
            v-for="(service,i) in orderBy(listServiceHebergements, ['id'])" 
            :key="i"
          >
            <v-col 
              v-if="service.custom_service"
              cols="3" 
            >
              <form-fields-multi-select 
                v-model="service.nom"
                label="Liste des services"
                required
                :items="serviceDatacenters"
                item-text="nom"
                item-value="id"
                class="mb-5"
                @change="updateServiceHebergement(service.id)"
              />
            </v-col>
            <v-col 
              :cols="service.custom_service ? '3' : '6'" 
              class="d-flex"
            >
              <form-fields-multi-select 
                v-model="service.type_hebergement"
                :field-label="service.custom_service ? '' : service.nom"
                label="Type d'hébergement" 
                :items="typeHebergementsServices"
                item-text="label"
                item-value="id"
                @change="updateServiceHebergement(service.id)"
              />
              <base-button-icon 
                icon="$trashPurple"
                class="ml-2"
                @click="deleteServiceHebergement(service.id)" 
              />
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col 
                  cols="3" 
                  class="d-flex justify-center"
                >
                  <span 
                    v-if="service.type_hebergement === '100% En propre'"
                    class="text-body-2"
                  >
                    100%
                  </span>
                  <form-fields-text
                    v-else-if="service.type_hebergement === 'Hybride (% à préciser)'"
                    v-model="service.part_hebergement_propre"
                    label="Pourcentage"
                    type="number" 
                    input-suffix="%"
                    number-integer
                    :error-messages="errorMessage"
                    @blur="saveFormAnswer"
                    @change="validatePourcentage"
                  />
                  <span 
                    v-else
                    class="text-body-2"
                  >
                    0%
                  </span>
                </v-col>
                <v-col 
                  cols="3" 
                  class="d-flex justify-center"
                >
                  <span 
                    v-if="service.type_hebergement === '100% Colocation'"
                    class="text-body-2"
                  >
                    100%
                  </span>
                  <form-fields-text
                    v-else-if="service.type_hebergement === 'Hybride (% à préciser)'"
                    v-model="service.part_housing_colocation"
                    label="Pourcentage"
                    type="number" 
                    input-suffix="%"
                    number-integer
                    :error-messages="errorMessage"
                    @blur="saveFormAnswer"
                    @change="validatePourcentage"
                  />
                  <span 
                    v-else
                    class="text-body-2"
                  >
                    0%
                  </span>
                </v-col>
                <v-col 
                  cols="3" 
                  class="d-flex justify-center"
                >
                  <span 
                    v-if="service.type_hebergement === '100% IAAS/PAAS'"
                    class="text-body-2"
                  >
                    100%
                  </span>
                  <form-fields-text
                    v-else-if="service.type_hebergement === 'Hybride (% à préciser)'"
                    v-model="service.part_iaas_paas"
                    label="Pourcentage"
                    type="number" 
                    input-suffix="%"
                    number-integer
                    :error-messages="errorMessage"
                    @blur="saveFormAnswer"
                    @change="validatePourcentage"
                  />
                  <span 
                    v-else
                    class="text-body-2"
                  >
                    0%
                  </span>
                </v-col>
                <v-col 
                  cols="3" 
                  class="d-flex justify-center"
                >
                  <span 
                    v-if="service.type_hebergement === '100% SAAS'"
                    class="text-body-2"
                  >
                    100%
                  </span>
                  <form-fields-text
                    v-else-if="service.type_hebergement === 'Hybride (% à préciser)'"
                    v-model="service.part_service_saas"
                    label="Pourcentage"
                    type="number" 
                    input-suffix="%"
                    number-integer
                    :error-messages="errorMessage"
                    @blur="saveFormAnswer"
                    @change="validatePourcentage"
                  />
                  <span 
                    v-else
                    class="text-body-2"
                  >
                    0%
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-row v-if="service.nom === 'Autre'">
              <v-col cols="12">
                <form-fields-text
                  v-model="service.autreNom"
                  required
                  field-label="Préciser le nom du service d'hébergement"
                  placeholder="Nom du service d'hébergement"
                  class="mb-2"
                  @blur="updateServiceHebergement(service.id)"
                />
              </v-col>
            </v-row>
            <v-row v-if="service.type_hebergement === 'Hybride (% à préciser)'" />
          </v-row>
          <v-row>
            <v-col cols="6 offset-6">
              <form-button-add-element 
                :disabled="disabledButton"
                label="Ajouter un service" 
                outlined
                @click="createServiceHebergement"
              />
            </v-col>
          </v-row>
          <form-fields-text 
            v-model="pourcentage_volume_infra_totales"
            field-label="Cette liste représente (en % du volume des infrastructures totales)"
            placeholder="% du volume des infrastructures"
            input-suffix="%"
            type="number"
            number-integer
            class="mt-9"
            @blur="saveFormAnswer"
          />
        </v-col> -->
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormStepTitle from "@/components/FormStepTitle"
import FormFieldsText from '@/components/FormFieldsText.vue'
import { mapActions, mapMutations, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import BaseSubtitle from '@/components/BaseSubtitle.vue';
import BaseCommentaire from '@/components/BaseCommentaire.vue';
import { orderBy } from 'lodash'

export default {
  name: "Host",
  components: {
    FormStepTitle,
    FormFieldsText,
    BaseSubtitle,
    BaseCommentaire,
  },
  data () {
    return {
      herbergementList: [
        'Hébergement en propre',
        'Housing/Colocation',
        'IAAS/PAAS',
        'Service SAAS'
      ],
      errorMessage:"",
    }
  },
  computed: {
    ...mapFields('form', [
      'formAnswer.ressources_presence_cloud',
      'formAnswer.ressources_pourcentage_cloud',
      'formAnswer.ressources_presence_paas',
      'formAnswer.ressources_pourcentage_paas',
      'formAnswer.ressources_presence_saas',
      'formAnswer.ressources_pourcentage_saas',
      'formAnswer.ressources_presence_hybride',
      'formAnswer.ressources_pourcentage_hybride',
      'formAnswer.ressources_presence_propre',
      'formAnswer.ressources_pourcentage_propre',
      'formAnswer.infra_hebergement_nombre_serveurs_physiques',
      'formAnswer.infra_hebergement_nombre_vm',
      'formAnswer.infra_hebergement_nombre_applications',
      'formAnswer.infra_hebergement_nombre_serveurs_dedies_bdd',
      'formAnswer.pourcentage_volume_infra_totales',
    ]),
    ...mapState('form', ['typeHebergementsServices', 'listServiceHebergements', 'entite']),
    disabledButton() {
      return this.listServiceHebergements.some(service => service.nom === '');
    }
  },
  created() {
    this.fetchEntite();
    this.fetchFormAnswer();
    this.fetchListServiceHebergements();
    this.fetchTypeHebergementsServices();
    this.setNextView('/ressources/site-definition');
  },
  methods: {
    orderBy,
    ...mapMutations('stepper', ['setNextView']),
    ...mapActions('form', [
      'fetchFormAnswer',
      'saveFormAnswer',
      'fetchTypeHebergementsServices',
      'fetchTypeHebergementsServices',
      'updateServiceHebergement',
      'fetchListServiceHebergements',
      'createServiceHebergement',
      'deleteServiceHebergement',
      'fetchEntite'
    ]),
    validatePourcentage() {
      const value = Number(this.pourcentage_service_hybride_propre) + Number(this.pourcentage_service_hybride_iaas_paas) + Number(this.pourcentage_service_hybride_colocation) + Number(this.pourcentage_service_hybride_saas)
      
      if (value !== 100) {
        this.errorMessage = "La somme des 4 champs doit être égale à 100%"
      } else {
        this.errorMessage= ""
      }
    },
  } 
}
</script>